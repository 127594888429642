import React, { useState, useEffect } from "react";
import Input from "../Input";
import Button from "../Button";
import useToasts from "../../hooks/useToasts";
import Loading from "../Loading";
import useLocalStorage from "../../hooks/useLocalStorage";
import LocationDropdown from "../LocationDropdown";
import PaymentForm from "../PaymentForm";

const BookingForm = () => {
	const [{ fullName, email }, setState] = useState({
		fullName: null,
		email: null
	});
	const toast = useToasts();
	const [loading, setLoading] = useState(false);
	const [token] = useLocalStorage("token", null);

	const [locationStart, setLocationStart] = useState("Start Location");
	const [locationEnd, setLocationEnd] = useState("End Location");
	const [isLoading, setIsLoading] = useState(true);
	const [locationsStart, setLocationsStart] = useState([]);
	const [locationsEnd, setLocationsEnd] = useState([]);
	const [products, setProducts] = useState([]);
	const [product, setProduct] = useState("Time Period");
	const [bookSet, setBookSet] = useState(false);
	const [paymentIntent, setPaymentIntent] = useState(null);

	const checkLocations = (locations) => {
		locations.forEach((location, index) => {
			if (location.scooterCount <= 0) {
				locations.splice(index, 1);
				checkLocations(locations);
			}
		});
		return locations;
	};

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/locations`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token

				}
			});
			const data = await response.json();


			checkLocations(data.locations);

			setLocationsStart(data.locations);

			const responseL = await fetch(`${process.env.REACT_APP_BASE_API_URL}/locations`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token

				}
			});
			const dataL = await responseL.json();

			console.log(dataL);

			setLocationsEnd(dataL.locations);

			const responseP = await fetch(`${process.env.REACT_APP_BASE_API_URL}/products`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token

				}
			});
			const dataP = await responseP.json();


			setProducts(dataP.products);

			setIsLoading(false);
		})();
	}, []);


	if (loading || isLoading)
		return (<Loading />);

	const handleChange = (event) => {
		setState((state) => ({
			...state,
			[event.target.name]: event.target.value
		}));
	};

	const handleLocation = (selected) => {
		for (let x=0; x < locationsEnd.length; x++) {
			if (locationsEnd[x].name === selected)
				return locationsEnd[x].id;
		}
		return 0;
	};

	const handleProduct = (selected) => {
		for (let x=0; x < products.length; x++) {
			if (products[x].name === selected)
				return products[x].id;
		}
		return 0;
	};

	const handleCreateBooking = async () => {
		try {
			if (!fullName) {
				toast("Error!", "No Name", 5000, "bg-red-500");
				return;
			}
			if (!email) {
				toast("Error!", "No Email", 5000, "bg-red-500");
				return;
			}
			if (locationStart === "Start Location") {
				toast("Error!", "No Start Location", 5000, "bg-red-500");
				return;
			}
			if (locationEnd === "End Location") {
				toast("Error!", "No End Location", 5000, "bg-red-500");
				return;
			}
			if (product === "Time Period") {
				toast("Error!", "No Time Period", 5000, "bg-red-500");
				return;
			}

			setLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/unregistered`, {
				method: "POST",
				body: JSON.stringify({
					startLocation: handleLocation(locationStart),
					endLocation: handleLocation(locationEnd),
					product: handleProduct(product),
					name: fullName,
					email: email
				}),
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			let responsePI = await fetch(`${process.env.REACT_APP_BASE_API_URL}/bookings/${body.booking.id}`, {
				headers: {
					authorization: "Bearer " + token
				}
			});

			let bodyPI = await responsePI.json();

			setPaymentIntent(bodyPI.booking);

			if (response.ok) {
				// navigate("/scootrs");
				console.log(body);
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setLoading(false);
			setBookSet(true);
		} catch (err) {
			setLoading(false);

			console.log(err);

			if (!err.response)
				err.response = { message: "An unknown error occurred 1" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred 2";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	return (
		<div className=''>
			{bookSet? (<PaymentForm booking={paymentIntent}/>):
				(<div className='mx-auto w-96 py-4'>
					<Input name='fullName' onInput={handleChange} placeholder='Name' type='name'/>
					<Input name='email' onInput={handleChange} placeholder='Email' type='email'/>

					<br /><br/>

					<LocationDropdown selected={locationStart} setSelected={setLocationStart} locations={locationsStart}/>
					<LocationDropdown selected={locationEnd} setSelected={setLocationEnd} locations={locationsEnd}/>
					<LocationDropdown selected={product} setSelected={setProduct} locations={products}/>

					<br /><br />

					<Button text='Create Booking' onClick={handleCreateBooking}/>

				</div>
				)}
		</div>
	);
};

export default BookingForm;

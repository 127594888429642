import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import Error from "../components/Error";
import BookingForm from "../components/form/BookingForm";

const CreateBooking = () => {
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	if (loading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (!user)
		navigate("/login");

	return (
		<div className=''>
			<Navbar active={5}/>

			<div className="mx-auto w-96 py-4">
				<h1 className="text-scootr-Orange text-2xl my-4">Create booking for unregistered user</h1>

				<BookingForm />

			</div>
		</div>
	);
};

export default CreateBooking;

import React from "react";
import LoginForm from "../components/form/LoginForm";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";
import Error from "../components/Error";

const Login = () => {
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	if (loading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (user) {
		navigate("/");
		return null;
	}

	return (
		<div>
			<div className="mx-auto w-96">
				<img alt="logo" className="mx-auto w-96 h-96" src="/logo.png"/>
				<LoginForm />
			</div>
		</div>
	);
};

export default Login;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useLocalStorage from "../hooks/useLocalStorage";
import Loading from "../components/Loading";
import Error from "../components/Error";
import Navbar from "../components/Navbar";
import IncomeChart from "../components/dashboard/IncomeChart";
import StatBox from "../components/dashboard/StatBox";
import Spinner from "../components/Spinner";

const Dashboard = () => {
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	const [token] = useLocalStorage("token", null);
	const [dashboardError, setDashboardError] = useState(null);
	const [dashboardLoading, setDashboardLoading] = useState(true);
	const [data, setData] = useState(null);

	useEffect(async () => {
		setDashboardLoading(true);

		try {
			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/reports/weekly`, {
				headers: {
					authorization: "Bearer " + token
				}
			});

			const body = await response.json();

			if (response.ok)
				setData(body.data);
			else
				setDashboardError(response.status);

			setDashboardLoading(false);
		} catch (_) {
			setDashboardError(500);
			setDashboardLoading(false);
		}
	}, []);

	if (loading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (dashboardError)
		return (<Error code={dashboardError} />);

	if (!user) {
		navigate("/");
		return null;
	}

	return (
		<div>
			<Navbar active={1} />
			<div className="h-screen flex justify-center pt-4 sm:pt-8 p-2">
				<div className="container mx-auto px-4">
					<h1 className="text-primary text-6xl font-bold mb-2">Dashboard</h1>
					<div className="text-slate-500">A summary of the last 7 day&apos;s hiring data</div>
					<>
						{dashboardLoading ? (
							<div className="justify-center flex flex-row">
								<Spinner size="mt-10 h-40 w-40"/>
							</div>
						) : (
							<>
								<div className="mt-10 mb-10 grid grid-cols-1 sm:grid-cols-3 flex-wrap gap-4 sm:gap-8">
									<StatBox title="Total Scootrs Booked" value={data.totalBookings} />
									<StatBox title="Unique Customers" value={data.uniqueCustomers} />
									<StatBox title="Total Income" value={`£${data.totalIncome / 100}`} />
								</div>
								<h2 className="text-primary text-3xl font-bold">Income Chart</h2>
								<div className="mt-4 h-1/2 w-full">
									<IncomeChart data={data.datasets} />
								</div>
							</>
						)}
					</>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;

import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Dropdown from "../Dropdown";
import useToasts from "../../hooks/useToasts";
import useLocalStorage from "../../hooks/useLocalStorage";
import Loading from "../Loading";

const listStatus = [
	"Scooter Available", "Scooter Booked", "Scooter Maintenance", "Scooter Missing"
];


const EditModal = (props) => {
	const cancelButtonRef = useRef(null);
	const [state, setStatus] = useState("Select Status");
	const toast = useToasts();
	const [loading, setLoading] = useState(false);
	const [token] = useLocalStorage("token", null);

	if (loading)
		return (<Loading />);

	const handleChangeStatus = async () => {
		try {
			if (!props.id) {
				toast("Error!", "No ID", 5000, "bg-red-500");
				return;
			}
			if (!confirm) {
				toast("Error!", "No Confirm", 5000, "bg-red-500");
				return;
			}
			if (!state) {
				toast("Error!", "No Condition", 5000, "bg-red-500");
				return;
			}

			setLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/scooters/${props.id}`, {
				method: "PATCH",
				body: JSON.stringify({
					status: listStatus.indexOf(state)
				}),
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok) {
				window.location.reload();
				return;
			}
			toast("Error!", body.message, 5000, "bg-red-500");

			setLoading(false);
		} catch (err) {
			setLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleConfirm = () => {
		handleChangeStatus();
		props.setOpen(false);
	};


	return (
		<Transition.Root show={props.open} as="div">
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => props.setOpen(false)}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative h-[20rem] inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="container p-3">
								<h3 className="text-center">Change Scootr Status</h3>
								<Dropdown selected={state} setSelected={setStatus} list={listStatus}/>
							</div>
							<div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base
                                    font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3
                                    sm:w-auto sm:text-sm"
									onClick={handleConfirm}
								>
                  Confirm
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2
                                    bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
                                    focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => props.setOpen(false)}
									ref={cancelButtonRef}
								>
                  Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
export default EditModal;

import React, { useState, useEffect } from "react";
import Input from "../Input";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import useToasts from "../../hooks/useToasts";
import Loading from "../Loading";
import useLocalStorage from "../../hooks/useLocalStorage";
import LocationDropdown from "../LocationDropdown";

const AddScootrForm = () => {
	const [{ number, status }, setState] = useState({
		number: null,
		status: null
	});
	const toast = useToasts();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [token] = useLocalStorage("token", null);

	const [location, setLocation] = useState("Locations");
	const [isLoading, setIsLoading] = useState(true);
	const [locations, setLocations] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/locations`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token

				}
			});
			const data = await response.json();

			setLocations(data.locations);
			setIsLoading(false);
		})();
	}, []);


	if (loading || isLoading)
		return (<Loading />);

	const handleChange = (event) => {
		setState((state) => ({
			...state,
			[event.target.name]: event.target.value
		}));
	};

	const handleLocation = (selected) => {
		for (let x=0; x < locations.length; x++) {
			if (locations[x].name === selected)
				return locations[x].id;
		}
		return 0;
	};

	const handleAddScootr = async () => {
		try {
			if (!number) {
				toast("Error!", "No Number", 5000, "bg-red-500");
				return;
			}
			if (!location) {
				toast("Error!", "No Location", 5000, "bg-red-500");
				return;
			}
			if (!status) {
				toast("Error!", "No Condition", 5000, "bg-red-500");
				return;
			}

			setLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/scooters`, {
				method: "POST",
				body: JSON.stringify({
					status: parseInt(status, 10),
					number: parseInt(number, 10),
					location: handleLocation(location)
				}),
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();

			if (response.ok)
				navigate("/scootrs");
			else
				toast("Error!", body.message, 5000, "bg-red-500");

			setLoading(false);
		} catch (err) {
			setLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	return (
		<div className=''>
			<div className='mx-auto w-96 py-4'>
				<h1 className='mx-auto text-center text-scootr-Orange text-2xl'>Add a new Scootr</h1>
				<br/>
				<Input name='number' onInput={handleChange} placeholder='Number'/>
				{/* <Input name='location' onInput={handleChange} placeholder='Location'/> */}
				<Input name='status' onInput={handleChange} placeholder='status'/>
				<LocationDropdown selected={location} setSelected={setLocation} locations={locations}/>

				<br /><br />

				<Button text='Add' onClick={handleAddScootr}/>
			</div>
		</div>
	);
};

export default AddScootrForm;

import React from "react";
import { useState } from "react";
import EditModal from "./form/EditModal";
import Button from "./Button";

const ScootrList = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [id, setId] = useState();
	const openModal = (given) => {
		setId(given);
		setIsOpen(true);
	};

	return (
		<div className="container">
			<h3 className="p-3 text-center">List of all Scootrs</h3>
			<table className="w-full table table-striped table-bordered">
				<thead>
					<tr>
						<th>Number</th>
						<th>Location</th>
						<th>Status</th>
						<th>Edit Status</th>
					</tr>
				</thead>
				<tbody>
					{props.list.map(scootr =>
						<tr key={scootr.id}>
							<td>{scootr.number}</td>
							<td>{scootr.location.name}</td>
							<td>{scootr.status}</td>
							<td> <Button text='Edit' onClick={()=>(openModal(scootr.id))}/></td>

						</tr>

					)}
				</tbody>
			</table>
			<EditModal open={isOpen} setOpen={setIsOpen} id={id} />
		</div>
	);
};

export default ScootrList;

import React from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import useAuth from "../hooks/useAuth";

const Navbar = (props) => {
	const { className, ...otherProps } = props;
	const [token, setToken] = useLocalStorage("token", null);
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	if (token === null) {
		navigate("/");
		return null;
	}

	if (loading || error || !user)
		return null;

	return (
		<div className={ className + " bg-scootr-Orange w-100 py-2 px-4 flex"}>
			<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start ">
				<div className="hidden sm:block sm:ml-6 flex-shrink-0 flex items-center">
					<div className="flex space-x-4 items-center">
						{user.accessLevel > 1 ? (
							<>
								<p className="text-scootr-White font-bold">
									You don't have access to this dashboard
								</p>
								
								<a href="/" onClick={() => {
									setToken(null);
								}} className={
									otherProps.active === -1 ?
										"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
										"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
								}>Sign Out</a>
							</>
						) : (
							<>
								{user.accessLevel === 0 ? (
									<a href="/dashboard" className={
										otherProps.active === 1 ?
											"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
											"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
									} aria-current="page">Dashboard</a>
								) : <></>}

								<a href="/scootrs" className={
									otherProps.active === 2 ?
										"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
										"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
								}>Manage Scootrs</a>

								{user.accessLevel === 0 ? (
									<a href="/prices" className={
										otherProps.active === 3 ?
											"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
											"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
									}>Manage Prices</a>
								) : <></>}

								<a href="/feedback" className={
									otherProps.active === 4 ?
										"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
										"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
								}>Feedback</a>
								<a href="/booking" className={
									otherProps.active === 5 ?
										"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
										"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
								}>Create Booking</a>

								<a href="/" onClick={() => {
									setToken(null);
								}} className={
									otherProps.active === -1 ?
										"bg-scootr-Black text-scootr-White px-3 py-2 rounded-md text-sm font-medium" :
										"text-scootr-White px-3 py-2 rounded-md text-sm font-medium"
								}>Sign Out</a>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;

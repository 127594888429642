import React from "react";

const Button = (props) => {
	const { className, ...otherProps } = props;

	return (
		<button {...otherProps} type="submit" className={className +
				" group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md " +
				"text-white bg-scootr-Orange hover:bg-scootr-Orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-scootr-Blue"}>
			{props.text}
		</button>
	);
};

export default Button;

import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Loading from "../components/Loading";
import Error from "../components/Error";
import AddScootrForm from "../components/form/AddScootrForm";
import Navbar from "../components/Navbar";


const AddScootr = () => {
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	if (loading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (!user)
		navigate("/");

	return (
		<div className=''>
			<Navbar />
			<div className='"flex-1 flex items-center justify-center justify-evenly"'>
				<AddScootrForm />
			</div>
		</div>
	);
};

export default AddScootr;

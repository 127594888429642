import React from "react";

const InputPrice = (props) => {
	const { className, error, ...otherProps } = props;


	return (
		<div className={
			"flex item-center bg-scootr-White placeholder:text-neutral-300 text-scootr-Black transition w-full my-1 " +
			"duration-250 px-2 outline-none py-1 rounded-lg border-2 border-scootr-Black-500 " +
			`focus:border-scootr-Orange ${className ? className : ""} ${error ? "bg-red-500" : ""}`
		}>
			<div className="flex flex-1 items-center justify-center px-1 justify-center item-center">£</div>
			<input {...otherProps}
				className={
					"bg-scootr-White placeholder:text-neutral-300 text-scootr-Black transition w-full my-1 " +
					"duration-250 outline-none " +
					`focus:border-scootr-Orange ${className ? className : ""} ${error ? "bg-red-500" : ""}`
				}
			/>
		</div>
	);
};

export default InputPrice;

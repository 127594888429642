import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import Error from "../components/Error";
import Loading from "../components/Loading";


const ErrorPage = (props) => {
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	if (loading)
		return (<Loading />);

	if (error)
		return (<div>error with displaying {props.code}</div>);


	if (!user)
		navigate("/login");


	return (
		<div className='h-screen flex flex-col'>
			<Navbar active={0}/>
			<Error code={props.code} />
		</div>
	);
};

export default ErrorPage;

import { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";

const useAuth = () => {
	const [token, setToken] = useLocalStorage("token", null);
	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);

			let headers = {};

			if (token)
				headers.authorization = "Bearer " + token;

			try {
				const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/account`, {
					headers: headers
				});
				const body = await response.json();

				if (response.ok)
					setUser(body.user);
				else if (response.status === 401)
					setToken(null);
				else
					setError(response.status);
			} catch (_) {
				setError(500);
			}

			setLoading(false);
		})();
	}, []);

	return { user, loading, error };
};

export default useAuth;

import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";


const Dropdown = ({ selected, setSelected, list }) => {
	return (
		<div className="flex items-center justify-center my-1">
			<div className="w-full mx-auto">
				<Listbox
					as="div"
					className="space-y-1"
					value={selected}
					onChange={setSelected}
				>
					{({ open }) => (
						<>
							<Listbox.Label className="block text-sm leading-5 font-medium text-gray-700">
								{/* Location */}
							</Listbox.Label>
							<div className="relative">
								<span className="inline-block w-full rounded-md shadow-sm">
									<Listbox.Button className="cursor-default relative w-full rounded-md border-2 border-scootr-black-500
                                     bg-scootr-White pl-3 pr-10 py-2 text-left focus:outline-none focus:border-scootr-orange
                                     focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
										<span className="block truncate">{selected}</span>
										<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
											<svg
												className="h-5 w-5 text-gray-400"
												viewBox="0 0 20 20"
												fill="none"
												stroke="currentColor"
											>
												<path
													d="M7 7l3-3 3 3m0 6l-3 3-3-3"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</span>
									</Listbox.Button>
								</span>

								<Transition
									show={open}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
									className="absolute mt-1 z-10 w-full rounded-md bg-white shadow-lg"
								>
									<Listbox.Options
										static
										className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
									>
										{list.map((items) => (
											<Listbox.Option key={items} value={items}>
												{({ select, active }) => (
													<div
														className={`${
															active
																? "text-white bg-scootr-Orange"
																: "text-gray-900"
														} cursor-default select-none relative py-2 pl-8 pr-4`}
													>
														<span
															className={`${
																select ? "font-semibold" : "font-normal"
															} block truncate`}
														>
															{items}
														</span>
													</div>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</>
					)}
				</Listbox>
			</div>
		</div>
	);
};


export default Dropdown;

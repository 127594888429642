import React, { useState } from "react";
import Input from "../Input";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import useToasts from "../../hooks/useToasts";
import useLocalStorage from "../../hooks/useLocalStorage";
import Loading from "../Loading";


const LoginForm = () => {
	const [{ email, password }, setState] = useState({
		email: null,
		password: null
	});
	const toast = useToasts();
	const navigate = useNavigate();
	const [, setToken] = useLocalStorage("token", null);
	const [loading, setLoading] = useState(false);

	if (loading)
		return (<Loading />);


	const handleChange = (event) => {
		setState((state) => ({
			...state,
			[event.target.name]: event.target.value
		}));
	};

	const handleLogin = async () => {
		try {
			if (!email) {
				toast("Error!", "No email", 5000, "bg-red-500");
				return;
			}
			if (!password) {
				toast("Error!", "No password", 5000, "bg-red-500");
				return;
			}

			setLoading(true);

			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/login`, {
				method: "POST",
				body: JSON.stringify({
					email,
					password
				}),
				headers: {
					"content-type": "application/json"
				}
			});

			let body = await response.json();

			if (response.ok) {
				setToken(body.token);
				navigate("/");
			} else
				toast("Error!", body.message, 5000, "bg-red-500");

			setLoading(false);
		} catch (err) {
			setLoading(false);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	return (
		<div className=''>
			<div className='mx-auto w-96'>
				<h1 className='mx-auto text-center text-scootr-Orange text-2xl'>Welcome back!</h1>
				<br/>
				<Input name='email' onInput={handleChange} placeholder='Email' type='email'/>
				<Input name='password' onInput={handleChange} placeholder='Password' type='password'/>

				<br /><br />

				<Button text='Sign in' onClick={handleLogin}/>
			</div>
		</div>
	);
};

export default LoginForm;

import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import Error from "../components/Error";

const Home = () => {
	const { user, loading, error } = useAuth();
	const navigate = useNavigate();

	if (loading)
		return (<Loading />);

	if (error)
		return (<div><Error code={error} />herer</div>);


	if (!user)
		navigate("/login");


	return (
		<div className=''>
			<Navbar active={1}/>
		</div>
	);
};

export default Home;

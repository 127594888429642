import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useToasts from "../hooks/useToasts";


const Feedback = () => {
	const { user, loading, error } = useAuth();
	const [token] = useLocalStorage("token", null);
	const navigate = useNavigate();
	const [feedback, setFeedback] = useState([null]);
	const [isLoading, setIsLoading] = useState(true);
	const toast = useToasts();

	const getFeedbacks = async () => {
		setIsLoading(true);
		const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/feedback/active`, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				authorization: "Bearer " + token
			}
		});
		const data = await response.json();

		setFeedback(data);
		setIsLoading(false);
	};

	useEffect(() => {
		getFeedbacks();
	}, []);

	if (loading || isLoading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (!user)
		navigate("/login");

	const handleResolve = async (feedbackID) => {
		try {
			setIsLoading(true);

			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/feedback/resolve`, {
				method: "POST",
				headers: {
					authorization: "Bearer " + token
				},
				body: JSON.stringify({
					id: feedbackID
				})
			});

			let body = await response.json();

			if (!response.ok)
				toast("Error!", body.message, 5000, "bg-red-500");


			setIsLoading(false);
			getFeedbacks();
		} catch (err) {
			setIsLoading(false);

			console.log(err);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};

	const handleIncreasePriority = async (feedbackID, feedbackPriority) => {
		try {
			setIsLoading(true);

			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/feedback/priority`, {
				method: "POST",
				headers: {
					authorization: "Bearer " + token
				},
				body: JSON.stringify({
					id: feedbackID,
					priority: feedbackPriority
				})
			});

			let body = await response.json();

			if (!response.ok)
				toast("Error!", body.message, 5000, "bg-red-500");


			setIsLoading(false);
			getFeedbacks();
		} catch (err) {
			setIsLoading(false);

			console.log(err);

			if (!err.response)
				err.response = { message: "An unknown error occurred" };
			else if (!err.response.message)
				err.response.message = "An unknown error occurred";

			toast("Error!", err.response.message, 5000, "bg-red-500");
		}
	};


	return (
		<div className=''>
			<Navbar active={4}/>
			<div className='max-w-xl mx-auto mt-4'>
				<div className="flex items-center justify-center flex-grow flex-col w-full">
					<div className='flex mx-auto w-full  px-4 py-4 flex-col'>
						<div className="bg-scootr-White shadow overflow-hidden sm:rounded-lg">
							<div className="px-4 py-5 sm:px-6">
								<h1 className="text-lg leading-6 font-medium text-gray-900">Feedback</h1>
								<p className="mt-1 max-w-2xl text-sm text-gray-500">All the feedback in order of date recieved is below.</p>
							</div>
							<div className="border-t border-gray-200">
								<dl>
									<div>
										<div className="bg-scootr-white px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
											<dt className="text-sm font-bold text-scootr-Black">Booking ID</dt>
											<dt className="text-sm font-bold text-scootr-Black text-center">Priority</dt>
											<dt className="mt-1 text-sm text-scootr-Black sm:mt-0 sm:col-span-4 font-bold">Feedback</dt>
											<dt className="mt-1 text-sm text-scootr-Black sm:mt-0 sm:col-span-1 font-bold">Actions</dt>
										</div>
									</div>
									{feedback && feedback.feedbacks.map(feed =>
										<div key={feed.id}>
											<div className="bg-scootr-Black px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
												<dt className="text-sm font-medium text-scootr-White">{String(feed.bookingID).substring(0, 9)}...</dt>
												<dt className="text-sm font-medium text-scootr-White text-center">{feed.priority}</dt>
												<dt className="mt-1 text-sm text-scootr-White sm:mt-0 sm:col-span-4 font-medium">{feed.text}</dt>
												<dt className="mt-1 text-sm text-scootr-White sm:mt-0 sm:col-span-1 font-medium">
													<a className={"pb-8 underline cursor-pointer"} onClick={() => handleResolve(feed.id)}>Resolve</a>
													<br />
													<a className={"underline cursor-pointer"} onClick={() => handleIncreasePriority(feed.id, (feed.priority + 1))}>Prioritise</a>
												</dt>
											</div>
										</div>
									)}
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feedback;

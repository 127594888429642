import React from "react";

const StatBox = ({ title, value }) => {
	return (
		<div className="w-full p-2 sm:p-6 bg-white rounded-lg shadow-lg items-center border-2 border-scootr-Orange">
			<p className="text-md sm:text-lg text-center font-bold  text-slate-500">{title}</p>
			<p className="w-full mt-2 text-primary text-scootr-Orange text-3xl sm:text-4xl text-center font-bold">{value}</p>
		</div>
	);
};

export default StatBox;

import React from "react";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

const IncomeChart = ({ data }) => {
	ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
	const options = {
		plugins: {
			title: {
				display: true,
				text: "Weekly Income"
			}
		},
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				title: {
					display: true,
					text: "Date"
				}
			},
			y: {
				stacked: true,
				title: {
					display: true,
					text: "Income (£)"
				}
			}
		}
	};

	const products = Object.keys(data);
	const days = Object.keys(data[products[0]]);

	const labels = days.map(day => {
		const date = new Date(Date.parse(day));
		return date.toLocaleDateString("en-GB");
	});

	const colourPalette = ["rgb(53, 162, 236)", "rgb(75, 192, 192)", "rgb(255, 99, 132)"];

	const chartData = {
		labels,
		datasets: products.map((product, index) => {
			return {
				label: product,
				data: days.map(label => data[product][label] / 100),
				backgroundColor: colourPalette[index]
			};
		})
	};

	return (
		<div className="flex flex-grow h-full w-full">
			<Bar options={options} data={chartData} />
		</div>
	);
};

export default IncomeChart;

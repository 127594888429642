import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const Error = (props) => {
	const buildMessage = (code) => {
		if (code === "404")
			return { title: "Not Found", description: "This page you are looking for might not exist" };
		else if (code === "401")
			return { title: "Unauthorised", description: "It appears you do noit have access to this page" };

		return { title: "Not Found", description: "This page you are looking for might not exist" };
	};

	const message = buildMessage(props.code);

	return (
		<div className="flex items-center justify-center flex-grow flex-col">
			<div className='flex mx-auto max-w-max  px-4 py-4 flex-col'>
				<div className="text-center">
					<ExclamationCircleIcon className="text-scootr-Orange w-20 justify-center mx-auto"/>
				</div>
				<div className='text-5xl text-scootr-Orange text-center'>{props.code + "  " + message.title}</div>
				<div className="text-center">{message.description}</div>
			</div>
		</div>
	);
};

export default Error;

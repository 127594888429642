import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import Error from "../components/Error";
import useToasts from "../hooks/useToasts";
import InputPrice from "../components/InputPrice";
import Button from "../components/Button";
import useLocalStorage from "../hooks/useLocalStorage";


const Prices = () => {
	const { user, loading, error } = useAuth();
	const [token ] = useLocalStorage("token", null);
	const [loaded, setLoaded] = useState(false);
	const [, setLoading] = useState(false);
	const navigate = useNavigate();
	const toast = useToasts();

	const [prices, setPrices] = useState([]);

	useEffect(async () => {
		if (!loaded) {
			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/products`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token
				}
			});

			let body = await response.json();
			console.log(body.products);
			setLoaded(true);
			body.products.forEach((item) => {
				item.rate = parseFloat(item.rate)/100;
			});
			setPrices(body.products);
		}
		if (error)
			toast("Error!", "unknown error", 5000, "bg-red-500");
	});

	if (loading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (!user)
		navigate("/login");

	const handleChange = (event) => {
		const priceid = event.target.getAttribute("data-id");
		const newPrices = [];

		prices.forEach((item) => {
			if (item.id !== priceid) {
				newPrices.push(item);
				console.log(newPrices);
			} else if (/^[0-9]*.[0-9]{2}$/.test(event.target.value)) {
				newPrices.push({
					id: priceid,
					name: item.name,
					period: item.period,
					rate: event.target.value,
					error: false,
					changed: true
				});
				console.log(newPrices);
			} else {
				newPrices.push({
					id: priceid,
					name: item.name,
					period: item.period,
					rate: event.target.value,
					error: true,
					changed: true
				});
			}
		});

		console.log("here123");
		console.log(prices);
		console.log(newPrices);
		setPrices(newPrices);
	};

	const handleUpdate = async () => {
		prices.forEach(async (item) => {
			if (item.error) {
				toast("Error!", "Price not in correct format", 5000, "bg-red-500");
				return;
			}
			if (item.changed) {
				try {
					setLoading(true);

					let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/products/${item.id}`, {
						method: "PATCH",
						headers: {
							"content-type": "application/json",
							authorization: "Bearer " + token
						},
						body: JSON.stringify({
							rate: parseInt(parseFloat(item.rate) * 100, 10)
						})
					});

					let body = await response.json();

					setLoading(true);


					if (response.ok)
						toast("Prices updates", "All prices successfully updated", 5000, "bg-green-500");
					else
						toast("Error!", body.message, 5000, "bg-red-500");

					setLoading(false);
				} catch (err) {
					setLoading(false);

					if (!err.response)
						err.response = { message: "An unknown error occurred" };
					else if (!err.response.message)
						err.response.message = "An unknown error occurred";

					toast("Error!", err.response.message, 5000, "bg-red-500");
				}
			}
		});
	};


	return (
		<div className=''>
			<Navbar active={3}/>

			<div className="mx-auto w-96 py-4">
				<h1 className="text-scootr-Orange text-2xl my-4">Hire Prices</h1>

				{console.log("here:")}
				{console.log(prices)}

				{prices.map(price =>
					<div key={price.id}>
						<p>{price.name}</p>
						<InputPrice data-id={price.id} error={price.error} onChange={handleChange} value={price.rate} />
					</div>
				)}

				<Button text='Update Prices' className="my-4" onClick={handleUpdate}/>
			</div>
		</div>
	);
};

export default Prices;

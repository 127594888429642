import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import Error from "../components/Error";
import Button from "../components/Button";
import ScootrList from "../components/ScootrList";
import { useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import Container from "../components/Container";

const Scootrs = () => {
	const { user, loading, error } = useAuth();
	const [token] = useLocalStorage("token", null);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [loadedScootrs, setLoadedScootrs] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/admin/scooters`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
					authorization: "Bearer " + token

				}
			});
			const data = await response.json();

			setLoadedScootrs(data.scooters);
			setIsLoading(false);
		})();
	}, []);

	if (loading || isLoading)
		return (<Loading />);

	if (error)
		return (<Error code={error} />);

	if (!user)
		navigate("/login");

	const handleAdd = () => {
		return navigate("/add");
	};

	return (
		<div className=''>
			<Navbar active={2}/>
			<div className="flex items-center justify-center">
				<Container>
					<ScootrList list={loadedScootrs} />
					<Button text='Add scootrs' onClick={handleAdd} className='mt-3'/>
				</Container>
			</div>
		</div>
	);
};

export default Scootrs;

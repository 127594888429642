import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Scootrs from "./pages/Scootrs";
import Prices from "./pages/Prices";
import ErrorPage from "./pages/ErrorPage";
import AddScootr from "./pages/AddScootr";
import Dashboard from "./pages/Dashboard";
import Feedback from "./pages/Feedback";
import CreateBooking from "./pages/CreateBooking";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {
	return (
		<Elements stripe={stripePromise} options={{
			fonts: [
				{
					cssSrc: "https://use.typekit.net/nbj3eyo.css"
				}
			]
		}}>
			<Routes>
				<Route path="/" element={<Home />} exact />
				<Route path="/home" element={<Home />} exact />
				<Route path="/login" element= {<Login />} exact />
				<Route path="/scootrs" element= {<Scootrs />} exact />
				<Route path="/add" element= {<AddScootr />} exact />
				<Route path="/prices" element= {<Prices />} exact />
				<Route path="/dashboard" element= {<Dashboard />} exact />
				<Route path="/feedback" element= {<Feedback />} exact />
				<Route path="/booking" element= {<CreateBooking />} exact />
				<Route path="/*" element= {<ErrorPage code="404" />} exact />
			</Routes>
		</Elements>
	);
};

export default App;

import React from "react";

const Input = (props) => {
	const { className, ...otherProps } = props;

	return (
		<input {...otherProps}
			className={
				"bg-scootr-White placeholder:text-neutral-300 text-scootr-Black transition w-full my-1 " +
				"duration-250 px-2 outline-none py-1 rounded-lg border-2 border-scootr-Black-500 " +
				`focus:border-scootr-Orange ${className ? className : ""}`
			}
		/>
	);
};

export default Input;
